<template>
    <div>
        <div class="media-page">
            <div class="header-overlay">
                <div class="header-text">媒體介紹</div>

                <div class="container-fluid" style="padding: 20px 40px 20px 18%;" v-if="$globalData.isMb">
                    <div class="row">
                        <div class="col-6 col-md-3" style="margin-top: 20px; text-align: left;">
                            <label class="check-box-container">
                                電視&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="checkbox" value="Tv" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-6 col-md-3" style="margin-top: 20px; text-align: left;">
                            <label class="check-box-container">
                                網上專訪
                                <input type="checkbox" value="Online" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-6 col-md-3" style="margin-top: 20px; text-align: left;">
                            <label class="check-box-container">
                                雜誌
                                <input type="checkbox" value="Book" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-6 col-md-3" style="margin-top: 20px; text-align: left;">
                            <label class="check-box-container">
                                實景單位
                                <input type="checkbox" value="Real" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-6 col-md-3" style="margin-top: 20px; text-align: left;">
                            <label class="check-box-container">
                                其他媒體
                                <input type="checkbox" value="Other" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" style="padding: 50px 40px;" v-if="!$globalData.isMb">
                    <div style="margin-left: 5%; width: 90%; height: 0px; border-bottom: 1px solid #fff;"></div>
                    <div class="row" style="padding-top: 10px;">
                        <div class="col-md-12" style="margin-top: 20px;">
                            <label class="check-box-container" style="margin-right: 43px;">
                                電視
                                <input type="checkbox" value="Tv" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                            <label class="check-box-container" style="margin-right: 43px;">
                                網上專訪
                                <input type="checkbox" value="Online" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                            <label class="check-box-container" style="margin-right: 43px;">
                                雜誌
                                <input type="checkbox" value="Book" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                            <label class="check-box-container" style="margin-right: 43px;">
                                實景單位
                                <input type="checkbox" value="Real" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                            <label class="check-box-container">
                                其他媒體
                                <input type="checkbox" value="Other" v-model="mediaOption" v-on:change="reloadData" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" style="margin-top: 8px; padding: 0 8px 20px 8px;">
            <div class="row g-2">
                <div class="col-12 col-md-4 news-wrapper" v-for="(row, index) in mediaDataSource" v-bind:key="index">
                    <!--<div class="news-wrapper-2" v-bind:style="getNewsBackgroundImage(row.cover)">-->
                    <div class="news-wrapper-2" v-on:click="loadDetail(row.id)">
                        <div class="news-wrapper-2-overlay">
                            <div class="text-wrapper">
                                <div style="font-size: 1.5em; letter-spacing: 5px;">{{row.cateName}}</div>
                                <div style="width: 100%; height: 1px; border-bottom: 1px solid #fff; margin: 10px 0 10px 0;"></div>
                                <div style="letter-spacing: 2px;">{{row.name}}</div>
                            </div>
                        </div>
                        <div class="news-wrapper-2-overlay-2" v-bind:style="getNewsBackgroundImage(row.cover)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-overlay" v-bind:style="heightPx" v-if="clickedNewsId != null" v-on:click="popupCloseButtonClick">

            <div class="news-window" @click.stop="">
                <div style="position: sticky; width: 100%; height: 65px; background-color: #fff; top: 0; z-index: 1000002;">
                    <div style="position: absolute; top: 0; right: 0; width: 65px; height: 65px; padding: 20px;">
                        <img style="width: 100%; height: 100%; cursor: pointer;" src="../assets/close.png" v-on:click="popupCloseButtonClick" />
                    </div>
                </div>

                <div style="margin-top:0px; position: relative;">
                    <div style="width: 100%;">
                        <div style="width: 100%; height: auto; position: relative; padding: 5px;" v-for="(atth, index) in mediaDetail.attachment" v-bind:key="index">
                            <a v-bind:href="getAttachmentPath(atth.a_path)" target="_blank" style="cursor: zoom-in;"><img v-bind:src="getAttachmentPath(atth.a_path)" style="width: 100%; height: auto;" /></a>
                        </div>
                        <div v-html="mediaDetail.description" style="margin-top: 10px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {
        GET_MEDIA_API_PATH, GET_MEDIA_OR_NEWS_DETAIL_API_PATH
    } from '../constants';

    export default {
        name: "Media",
        methods: {
            reloadData: function () {

                let that = this;
                let postData = { type: that.mediaOption };

                axios.post(GET_MEDIA_API_PATH, postData)
                    .then(function (res) {
                        that.mediaDataSource = res.data;
                    }).catch(function (ex) {
                        that.mediaDataSource = [];
                    });
            },
            loadDetail: function (newsId) {

                this.clickedNewsId = newsId;

                let that = this;
                let postData = { newsId: newsId };

                axios.post(GET_MEDIA_OR_NEWS_DETAIL_API_PATH, postData)
                    .then(function (res) {
                        that.mediaDetail = res.data;
                    }).catch(function (ex) {
                        that.mediaDetail = null;
                    });
            },
            getNewsBackgroundImage: function (path) {

                if (path == null)
                    return "";

                let style = 'background-image: url("/' + path + '"); background-size: cover; background-position: center;';
                return style;
            },
            getAttachmentPath: function (path) {
                return "/" + path;
            },
            popupCloseButtonClick: function () {
                this.clickedNewsId = null;
            }
        },
        created: async function () {

            if (this.$route.params.type != null)
                this.mediaOption.push(this.$route.params.type);

            await this.$nextTick();

            this.reloadData();
        },
        data() {
            return {
                mediaOption: [],
                mediaDataSource: [],
                clickedNewsId: null,
                mediaDetail: null
            };
        },
        computed: {
            heightPx: function () {
                let style = 'min-height: ' + (this.$globalData.windowHeight) + 'px;';
                return style;
            }
        }
    };
</script>
